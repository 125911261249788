import React from 'react';
import PropTypes from 'prop-types';
import DataSheet from '../../../components/common/DataSheet';
import ExpandCollapse from '../../../components/common/ExpandCollapse';
import { toDataSheetRows, valueRenderer } from './transformers/siteRfp';
import { useBBEContext } from '../../../core/store';
import { TOGGLE_EXPAND_COLLAPSE } from '../../../core/actions';
import { expandCollapseByTitleSelector } from '../../../core/selectors';
import { ReadOnlyContainer } from '../../../components/common/Interaction';
import { logUserAction } from '../../../utils/logUserAction';

function SiteList({ siteRfps, fields, locked }) {
  const { dispatch, state } = useBBEContext();
  const title = 'Site List';
  const siteRows = toDataSheetRows({
    siteRfps,
    fields,
  });
  return (
    <ExpandCollapse
      title={title}
      openByDefault={expandCollapseByTitleSelector(state, title)}
      onToggle={() => {
        logUserAction('toggle Site List container');
        dispatch({
          type: TOGGLE_EXPAND_COLLAPSE,
          payload: title,
        });
      }}
    >
      <ReadOnlyContainer readOnly={locked}>
        <DataSheet className="rfpDataSheet" data={siteRows} valueRenderer={valueRenderer} />
      </ReadOnlyContainer>
    </ExpandCollapse>
  );
}

SiteList.propTypes = {
  siteRfps: PropTypes.array,
  fields: PropTypes.array,
};

SiteList.defaultProps = {
  siteRfps: null,
  fields: null,
};

export default SiteList;
