import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Form, ButtonToolbar, Row, Col } from 'react-bootstrap';
import DataSheet from '../../../components/common/DataSheet';
import ExpandCollapse from '../../../components/common/ExpandCollapse';
import { toDataSheetRows, valueRenderer } from './transformers/rfpQuestion';
import { useBBEContext } from '../../../core/store';
import { TOGGLE_EXPAND_COLLAPSE } from '../../../core/actions';
import { expandCollapseByTitleSelector } from '../../../core/selectors';
import { ReadOnlyContainer } from '../../../components/common/Interaction';
import { Button } from '../../../components/common/BlackBearEnergy';
import { logUserAction } from '../../../utils/logUserAction';

const RfpQuestionsContainer = styled(Row)`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #cacbca;
`;

export function RfpQuestions({ questions, locked, onSubmit }) {
  const { dispatch, state } = useBBEContext();
  const [showModal, setShowModal] = useState(false);
  const [questionToSubmit, setQuestionToSubmit] = useState('');
  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setQuestionToSubmit('');
  }, []);

  const submitQuestion = useCallback(() => {
    onSubmit(questionToSubmit);
    setShowModal(false);
    setQuestionToSubmit('');
  }, [onSubmit, questionToSubmit]);

  const handleQuestionToSubmitChange = useCallback(e => {
    setQuestionToSubmit(e.target.value);
  }, []);

  const title = 'Channel Partner Questions for Black Bear';
  return (
    <RfpQuestionsContainer>
      <Col xs={12}>
        <ExpandCollapse
          title={title}
          openByDefault={expandCollapseByTitleSelector(state, title)}
          onToggle={() => {
            logUserAction('toggle RFPQuestions container');
            dispatch({
              type: TOGGLE_EXPAND_COLLAPSE,
              payload: title,
            });
          }}
        >
          <ReadOnlyContainer readOnly={locked}>
            <ButtonToolbar className="justify-content-end mb-2">
              <Button onClick={openModal}>Ask a Question</Button>
            </ButtonToolbar>
            <Modal show={showModal} onHide={closeModal}>
              <Modal.Body>
                <Form.Group className="mb-0" controlId="rfpQuestionsForm.rfpQuestion">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={questionToSubmit}
                    onChange={handleQuestionToSubmitChange}
                    rows={5}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={closeModal}>Cancel</Button>
                <Button onClick={submitQuestion}>Submit Question</Button>
              </Modal.Footer>
            </Modal>
            <DataSheet
              className="rfpDataSheet"
              data={toDataSheetRows(questions)}
              valueRenderer={valueRenderer}
            />
          </ReadOnlyContainer>
        </ExpandCollapse>
      </Col>
    </RfpQuestionsContainer>
  );
}

RfpQuestions.propTypes = {
  questions: PropTypes.array,
  locked: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

RfpQuestions.defaultProps = {
  questions: null,
  locked: false,
};

export default RfpQuestions;
