import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import DataSheet from '../../../components/common/DataSheet';
import ExpandCollapse from '../../../components/common/ExpandCollapse';
import { useBBEContext } from '../../../core/store';
import { TOGGLE_EXPAND_COLLAPSE } from '../../../core/actions';
import { expandCollapseByTitleSelector } from '../../../core/selectors';
import { ReadOnlyContainer } from '../../../components/common/Interaction';
import {
  removeDownloadUploadField,
  toDataSheetRows,
  valueRenderer,
} from './transformers/attachment';
import { RelativeContainer } from '../../../components/common/Layout';
import MeasuredGridRows from './layout/MeasuredGridRows';
import CascadingFileActionsOverlay from './file/CascadingFileActionsOverlay';
import { logUserAction } from '../../../utils/logUserAction';

export function DocumentUploads({ attachments, fields, locked, onDownload, onUpload }) {
  const { dispatch, state } = useBBEContext();
  const title = 'Document Uploads';

  // NOTE - This logic, critically was moved up the provider in order to do the offline processing of multiple files.
  const handleDropFile = ({ id }, { files }) => {
    onUpload(id, files);
  };

  const data = toDataSheetRows({
    attachments,
    fields: removeDownloadUploadField(fields),
    onDownload,
    addUploadDocumentHeader: true,
  });

  return (
    <ExpandCollapse
      title={title}
      openByDefault={expandCollapseByTitleSelector(state, title)}
      onToggle={() => {
        logUserAction('toggle Document Uploads container');
        dispatch({
          type: TOGGLE_EXPAND_COLLAPSE,
          payload: title,
        });
      }}
    >
      <ReadOnlyContainer readOnly={locked}>
        <RelativeContainer>
          <MeasuredGridRows>
            {({ rowHeights, columnWidths }) => (
              <Row>
                <Col xs={12}>
                  <DataSheet className="rfpDataSheet" data={data} valueRenderer={valueRenderer} />
                </Col>
                <CascadingFileActionsOverlay
                  hasTitle={false}
                  hideBid={true}
                  columnWidths={columnWidths}
                  rowHeights={rowHeights}
                  entities={attachments}
                  onDrop={handleDropFile}
                  showAttachmentsList={false}
                />
              </Row>
            )}
          </MeasuredGridRows>
        </RelativeContainer>
      </ReadOnlyContainer>
    </ExpandCollapse>
  );
}

DocumentUploads.propTypes = {
  attachments: PropTypes.array,
  fields: PropTypes.array.isRequired,
  locked: PropTypes.bool,
};

DocumentUploads.defaultProps = {
  attachments: null,
  locked: false,
};

export default DocumentUploads;
