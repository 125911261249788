import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, Form } from 'react-bootstrap';
import { SeparationRow } from '../../../components/common/Layout';
import ResizingTextArea from '../../../components/common/ResizingTextArea';

export function Questions({ questions, locked, onChangeQuestion }) {
  return (
    <SeparationRow>
      <Col xs={12}>
        <h5>Notes & Questions</h5>
        {_.chain(questions)
          .sortBy(_.property('dateCreated.value'))
          .map(question => {
            const debouncedOnChange = _.debounce(
              value =>
                onChangeQuestion({
                  ...question,
                  answer: {
                    value,
                  },
                }),
              1500,
            );
            return (
              <Form.Group key={_.get(question, 'id')}>
                <Form.Label style={{ whiteSpace: 'pre-wrap' }}>
                  {_.get(question, 'question.value')}
                </Form.Label>
                <ResizingTextArea
                  rows={1}
                  disabled={locked}
                  data-test-type="questionText"
                  defaultValue={_.get(question, 'answer.value')}
                  onChange={e => debouncedOnChange(_.get(e, 'target.value'))}
                />
              </Form.Group>
            );
          })
          .value()}
      </Col>
    </SeparationRow>
  );
}

Questions.propTypes = {
  questions: PropTypes.array,
  locked: PropTypes.bool,
  onChangeQuestion: PropTypes.func.isRequired,
};

Questions.defaultProps = {
  questions: null,
  locked: false,
};

export default Questions;
