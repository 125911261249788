import axios from 'axios';

export async function logUserAction(message) {
  await axios.request({
    url: '/api/internal/log',
    method: 'POST',
    data: {
      message,
    },
  });
}
