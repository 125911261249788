import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { SeparationRow } from '../../../components/common/Layout';
import { groupByScenario as groupSiteScenariosByScenario } from './transformers/siteScenario';
import ScenarioCard from './ScenarioCard';
import Pagination from '../../../components/Pagination';
import { useArrayDesignDetails, useStorageDesignDetails, useProjectSchedule } from '../hooks/rfp';
import CascadingSiteDataSheet from './CascadingSiteDataSheet';
import { SOLAR_ENERGY_TECHNOLOGY, STORAGE_ENERGY_TECHNOLOGY } from '../constants';
import ExpandCollapse from '../../../components/common/ExpandCollapse';
import { useNotifications } from '../../../core/hooks/notifications';
import { containsSolar, containsStorage } from './helpers/scenario';
import { useBBEContext } from '../../../core/store';
import { TOGGLE_EXPAND_COLLAPSE } from '../../../core/actions';
import { expandCollapseByTitleSelector } from '../../../core/selectors';
import { ReadOnlyContainer } from '../../../components/common/Interaction';
import { logUserAction } from '../../../utils/logUserAction';

const PAGE_SIZE = 15;

const ScenariosRow = styled(Row)`
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #cacbca;
`;

const setCustomizeWithConfirmationFactory = ({
  value,
  setter,
  sendConfirmation,
  message,
}) => newValue => {
  // NOTE - Case where confirmations was turned on
  if (value) {
    sendConfirmation('warning', {
      title: 'Warning: Changes Will Be Lost',
      onOk: () => setter(newValue),
      message,
    });
  } else {
    setter(newValue);
  }
};

function BidForm({
  sites,
  scenarios,
  scenarioFields,
  siteScenarios: allSiteScenarios,
  siteScenarioFields,
  locked,
  onChangeSiteScenario,
  onCascadeChangeToSiteScenarios,
  onCascadeBidToSiteScenarios,
}) {
  const { dispatch, state } = useBBEContext();
  const [__, sendConfirmation] = useNotifications(); // eslint-disable-line no-unused-vars

  const createToggleHandler = title => () => {
    logUserAction(`toggle ${title} container`);
    dispatch({
      type: TOGGLE_EXPAND_COLLAPSE,
      payload: title,
    });
  };

  const [
    arrayDesignSites,
    customizeArrayDesignByScenario,
    setArrayDesignOnCellChange,
    setCustomizedArrayDesignByScenario,
    setArrayDesignFilesForUpload,
  ] = useArrayDesignDetails();

  const onCustomizeArrayDesignByScenario = setCustomizeWithConfirmationFactory({
    value: customizeArrayDesignByScenario,
    setter: setCustomizedArrayDesignByScenario,
    message: 'Individual (per scenario) changes to array design details will be lost.',
    sendConfirmation,
  });

  const [
    storageDesignSites,
    customizeStorageDesignByScenario,
    setStorageDesignOnCellChange,
    setCustomizedStorageDesignByScenario,
    setStorageDesignFilesForUpload,
  ] = useStorageDesignDetails();

  const onCustomizeStorageDesignByScenario = setCustomizeWithConfirmationFactory({
    value: customizeStorageDesignByScenario,
    setter: setCustomizedStorageDesignByScenario,
    message: 'Individual (per scenario) changes to storage design details will be lost.',
    sendConfirmation,
  });

  const [
    projectScheduleSites,
    customizeProjectScheduleByScenario,
    setProjectScheduleOnCellChange,
    setCustomizedProjectScheduleByScenario,
  ] = useProjectSchedule();

  const onCustomizeProjectScheduleByScenario = setCustomizeWithConfirmationFactory({
    value: customizeProjectScheduleByScenario,
    setter: setCustomizedProjectScheduleByScenario,
    message: 'Individual (per scenario) changes to project schedules will be lost.',
    sendConfirmation,
  });

  const {
    arrayDesign: arrayDesignFields,
    bidSubmissionScenario: bidSubmissionScenarioFields,
    projectSchedule: projectScheduleFields,
    storageDesign: storageDesignFields,
  } = siteScenarioFields || {};

  const [page, setPage] = useState(0);

  const indexedScenarios = _.keyBy(scenarios, 'id');
  const groupedScenarios = _.chain(
    groupSiteScenariosByScenario({ siteScenarios: allSiteScenarios }),
  )
    .toPairs()
    .filter(([scenarioId]) => indexedScenarios[scenarioId])
    .sortBy(([scenarioId]) => _.get(indexedScenarios, [scenarioId, 'sequence', 'value']))
    .map(([scenarioId, siteScenarios], idx) => (
      <ScenarioCard
        key={scenarioId}
        locked={locked}
        sites={sites}
        scenario={indexedScenarios[scenarioId]}
        eventKey={String(idx)}
        siteScenarios={siteScenarios}
        scenarioFields={scenarioFields}
        arrayDesignFields={arrayDesignFields}
        storageDesignFields={storageDesignFields}
        projectScheduleFields={projectScheduleFields}
        siteScenarioFields={bidSubmissionScenarioFields}
        customizeArrayDesignByScenario={customizeArrayDesignByScenario}
        customizeStorageDesignByScenario={customizeStorageDesignByScenario}
        customizeProjectScheduleByScenario={customizeProjectScheduleByScenario}
        onChangeSiteScenario={onChangeSiteScenario}
      />
    ));
  const scenarioCards = groupedScenarios.chunk(PAGE_SIZE);

  const arrayDesignDetailsTitle = 'Array Design Details';
  const storageDesignDetailsTitle = 'Storage Design Details';
  const projectScheduleTitle = 'Project Schedule';

  const [arrayErrorCount, setArrayErrorCount] = useState(0);
  const [storageErrorCount, setStorageErrorCount] = useState(0);
  const [projectErrorCount, setProjectErrorCount] = useState(0);

  return (
    <SeparationRow>
      <Col xs={12}>
        <h4>Bid Form ({groupedScenarios.size().value()} Scenarios)</h4>
        <SeparationRow>
          <Col xs={12}>
            {containsSolar(scenarios, allSiteScenarios) && (
              <ExpandCollapse
                title={arrayDesignDetailsTitle}
                openByDefault={expandCollapseByTitleSelector(state, arrayDesignDetailsTitle)}
                errorCount={arrayErrorCount}
                onToggle={createToggleHandler(arrayDesignDetailsTitle)}
              >
                <ReadOnlyContainer readOnly={locked}>
                  <CascadingSiteDataSheet
                    fieldType="Array Design"
                    energyTechnology={SOLAR_ENERGY_TECHNOLOGY}
                    sites={arrayDesignSites}
                    fields={arrayDesignFields}
                    customizeByScenario={customizeArrayDesignByScenario}
                    showCustomize={true}
                    disableAllRows={customizeArrayDesignByScenario}
                    onChangeSite={setArrayDesignOnCellChange}
                    onChangeSiteFiles={setArrayDesignFilesForUpload}
                    onCustomizeByScenario={onCustomizeArrayDesignByScenario}
                    onCascadeChanges={onCascadeChangeToSiteScenarios}
                    onCascadeBid={onCascadeBidToSiteScenarios}
                    setErrorCount={setArrayErrorCount}
                    fileProperty="arrayLayout"
                    showActions={true}
                  />
                </ReadOnlyContainer>
              </ExpandCollapse>
            )}
            {containsStorage(scenarios, allSiteScenarios) && (
              <ExpandCollapse
                title={storageDesignDetailsTitle}
                openByDefault={expandCollapseByTitleSelector(state, storageDesignDetailsTitle)}
                errorCount={storageErrorCount}
                onToggle={createToggleHandler(storageDesignDetailsTitle)}
              >
                <ReadOnlyContainer readOnly={locked}>
                  <CascadingSiteDataSheet
                    fieldType="Storage Design"
                    energyTechnology={STORAGE_ENERGY_TECHNOLOGY}
                    sites={storageDesignSites}
                    fields={storageDesignFields}
                    customizeByScenario={customizeStorageDesignByScenario}
                    showCustomize={true}
                    disableAllRows={customizeStorageDesignByScenario}
                    onChangeSite={setStorageDesignOnCellChange}
                    onChangeSiteFiles={setStorageDesignFilesForUpload}
                    onCustomizeByScenario={onCustomizeStorageDesignByScenario}
                    onCascadeChanges={onCascadeChangeToSiteScenarios}
                    onCascadeBid={onCascadeBidToSiteScenarios}
                    setErrorCount={setStorageErrorCount}
                    fileProperty="storageDesign"
                    showActions={true}
                  />
                </ReadOnlyContainer>
              </ExpandCollapse>
            )}
            <ExpandCollapse
              title={projectScheduleTitle}
              openByDefault={expandCollapseByTitleSelector(state, projectScheduleTitle)}
              errorCount={projectErrorCount}
              onToggle={createToggleHandler(projectScheduleTitle)}
            >
              <ReadOnlyContainer readOnly={locked}>
                <Row>
                  <Col xs={8}>
                    <CascadingSiteDataSheet
                      fieldType="Project Schedule"
                      sites={projectScheduleSites}
                      fields={projectScheduleFields}
                      customizeByScenario={customizeProjectScheduleByScenario}
                      showCustomize={true}
                      disableAllRows={customizeProjectScheduleByScenario}
                      onChangeSite={setProjectScheduleOnCellChange}
                      onCustomizeByScenario={onCustomizeProjectScheduleByScenario}
                      onCascadeChanges={onCascadeChangeToSiteScenarios}
                      onCascadeBid={onCascadeBidToSiteScenarios}
                      setErrorCount={setProjectErrorCount}
                      overrideCustomButtonSizing={{
                        xs: 12,
                        xl: {
                          span: 8,
                          offset: 4,
                        },
                      }}
                    />
                  </Col>
                </Row>
              </ReadOnlyContainer>
            </ExpandCollapse>
          </Col>
        </SeparationRow>
        <ScenariosRow>
          <Col xs={12}>
            <h4>Scenarios</h4>
            {scenarioCards.get(page).value()}
          </Col>
          <Col
            xs={{
              span: 2,
              offset: 10,
            }}
          >
            {groupedScenarios.size().value() > PAGE_SIZE && (
              <Pagination
                lastDisabled={page === 0}
                nextDisabled={page >= scenarioCards.size().value() - 1}
                onNext={_.partial(setPage, page + 1)}
                onLast={_.partial(setPage, page - 1)}
              />
            )}
          </Col>
        </ScenariosRow>
      </Col>
    </SeparationRow>
  );
}

BidForm.propTypes = {
  scenarios: PropTypes.array,
  scenarioFields: PropTypes.array,
  siteScenarios: PropTypes.array,
  siteScenarioFields: PropTypes.shape({
    arrayDesign: PropTypes.array,
    bidSubmissionScenario: PropTypes.array,
    projectSchedule: PropTypes.array,
    storageDesign: PropTypes.array,
  }),
  locked: PropTypes.bool,
  onChangeSiteScenario: PropTypes.func.isRequired,
};

BidForm.defaultProps = {
  scenarios: null,
  scenarioFields: null,
  siteScenarios: null,
  siteScenarioFields: null,
  locked: false,
};

export default BidForm;
